import { useEffect, useState, useRef } from "react";
import { Card, Button } from "react-bootstrap";
import { BsChevronRight, BsSortDown, BsSortUp } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import NoDatFound from "../../Components/common/NoDatFound";
import { dispatch } from "../../redux";
import {
  getEAList,
  getEAUnauthorizedList,
} from "../../redux/action/expertAdvisorAction";
import { Store } from "../../redux/Actions";
import { DownloadsIcon } from "../../util/iconStyle";
import {
  customFormatDatetimeWithLocale,
  sort,
  thumbImageURL,
} from "../../util/utils";
import EA from "../../assets/img/EA.svg";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { ProgressDownload } from "../../Components/common/ProgressDownload";
import ToolFilter, {
  ToolFilterFunction,
} from "../../Components/common/ToolFilter";

export const ExpertAdvisors = () => {
  const navigate = useNavigate();
  const EAList = useSelector(
    (state: Store) => state.expertAdvisorReducer.EAList,
  ) as Array<Object>;
  const UnauthorizedEAList = useSelector(
    (state: Store) => state.expertAdvisorReducer.UnauthorizedEAList,
  ) as Array<Object>;
  const [Load, setLoad] = useState<any>([]);
  const btnRef = useRef<any>();

  const [lstEA, setLstEA] = useState<any>([]);
  const [sortBy, setSortBy] = useState("sortIndex");
  const [sortOrder, setSortOrder] = useState("ASC");

  const [unAusortBy, setunAuSortBy] = useState("sortIndex");
  const [unAusortOrder, setunAuSortOrder] = useState("ASC");

  const avaiableExpertAdvisorIsLoading = useSelector(
    (state: Store) =>
      state.expertAdvisorReducer.availableExpertAdvisorsIsLoading,
  ) as any;
  const unavailableExpertAdvisorIsLoading = useSelector(
    (state: Store) =>
      state.expertAdvisorReducer.unavailableExpertAdvisorsIsLoading,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.expertAdvisorReducer.isLoading,
  ) as any;
  const onSaveLoading = useSelector(
    (state: Store) => state.expertAdvisorReducer.onSaveLoading,
  ) as any;
  const [lstUnauthorizedEA, setLstUnauthorizedEA] = useState([]);

  const locale = useSelector((state: Store) => state.userDataReducer.local) as
    | "zh"
    | "en";

  const [
    authorizedExpertAdvisorListForRendering,
    setAuthorizedExpertAdvisorListForRendering,
  ] = useState([]);
  const [
    unauthorizedExpertAdvisorListForRendering,
    setUnauthorizedExpertAdvisorListForRendering,
  ] = useState([]);

  const [toolFilterTypeState, setToolFilterTypeState] = useState<
    "name" | "description"
  >("name");
  const [filteringQuery, setFilteringQuery] = useState("");

  useEffect(() => {
    dispatch(getEAList(false, { by: sortBy, order: sortOrder }));
    dispatch(getEAUnauthorizedList(false));
  }, []);

  useEffect(() => {
    const t: any = sort(lstEA, sortBy, sortOrder.toLowerCase());
    const filteredAuthorizedList = ToolFilterFunction(
      toolFilterTypeState,
      t,
      filteringQuery,
    );
    setAuthorizedExpertAdvisorListForRendering(filteredAuthorizedList);
  }, [sortOrder, sortBy, filteringQuery, toolFilterTypeState, lstEA]);

  useEffect(() => {
    const t: any = sort(
      lstUnauthorizedEA,
      unAusortBy,
      unAusortOrder.toLowerCase(),
    );
    const filteredUnAuthorizedList = ToolFilterFunction(
      toolFilterTypeState,
      t,
      filteringQuery,
    );
    setUnauthorizedExpertAdvisorListForRendering(filteredUnAuthorizedList);
  }, [
    unAusortOrder,
    unAusortBy,
    filteringQuery,
    toolFilterTypeState,
    lstUnauthorizedEA,
  ]);

  useEffect(() => {
    if (EAList) {
      const lst: any = [];
      EAList.map((ea: any) => {
        let classes = "";
        (ea?.classTools || []).map((c: any) => {
          classes = classes + ", " + c.class.name;
        });
        classes = classes.substring(1);
        lst.push({
          ...ea,
          classes: classes,
        });
      });
      setLstEA(lst);
    }
  }, [EAList]);

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const setunAuthSorting = (prop) => {
    if (unAusortBy === prop) {
      setunAuSortOrder(unAusortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setunAuSortBy(prop);
      setunAuSortOrder("ASC");
    }
  };

  useEffect(() => {
    if (UnauthorizedEAList) {
      const lst: any = [];
      UnauthorizedEAList.map((ea: any) => {
        let classes = "";
        (ea?.classTools || []).map((c: any) => {
          classes = classes + ", " + c.class.name;
        });
        classes = classes.substring(1);
        lst.push({
          ...ea,
          classes: classes,
        });
      });
      setLstUnauthorizedEA(lst);
    }
  }, [UnauthorizedEAList]);

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };

  return (
    <div className="content-wrapper">
      {(unavailableExpertAdvisorIsLoading ||
        avaiableExpertAdvisorIsLoading) && <CenterLoader />}

      <div className="content">
        {!isLoading &&
        !onSaveLoading &&
        (EAList || []).length <= 0 &&
        (UnauthorizedEAList || []).length <= 0 ? (
          <NoDatFound />
        ) : (
          <>
            <section className="page-content container-fluid" id="EA">
              <ToolFilter
                onChangeToolFilterType={setToolFilterTypeState}
                onChangeFilteringQuery={setFilteringQuery}
              />
              <header className="page-header">
                <div className="d-flex align-items-center">
                  <div className="mr-auto">
                    <h1>
                      <FormattedMessage
                        id="expert"
                        defaultMessage="Available Expert Advisors"
                      />
                    </h1>
                  </div>
                </div>
              </header>

              {lstEA.length ? (
                <Card style={{ width: "100%" }}>
                  <Card.Body>
                    <div className="table-responsive">
                      <table className="table table-hover table-striped m-0">
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="table.head.image"
                                defaultMessage="Image"
                              />{" "}
                              #
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => setSorting("name")}
                            >
                              <FormattedMessage
                                id="table.head.Name"
                                defaultMessage="Name"
                              />
                              {sortBy === "name" ? (
                                sortOrder === "DESC" ? (
                                  <BsSortDown />
                                ) : (
                                  <BsSortUp />
                                )
                              ) : null}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => setSorting("info")}
                            >
                              <FormattedMessage
                                id="table.head.Description"
                                defaultMessage="Description"
                              />
                              {sortBy === "info" ? (
                                sortOrder === "DESC" ? (
                                  <BsSortDown />
                                ) : (
                                  <BsSortUp />
                                )
                              ) : null}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => setSorting("sellingPrice")}
                            >
                              <FormattedMessage
                                id="table.head.Price"
                                defaultMessage="Price"
                              />
                              {sortBy === "sellingPrice" ? (
                                sortOrder === "DESC" ? (
                                  <BsSortDown />
                                ) : (
                                  <BsSortUp />
                                )
                              ) : null}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => setSorting("updatedAt")}
                            >
                              <FormattedMessage
                                id="table.head.LastUpdated"
                                defaultMessage="Last Update"
                              />
                              {sortBy === "updatedAt" ? (
                                sortOrder === "DESC" ? (
                                  <BsSortDown />
                                ) : (
                                  <BsSortUp />
                                )
                              ) : null}
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Details"
                                defaultMessage="Details"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Download"
                                defaultMessage="Download"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {authorizedExpertAdvisorListForRendering.length
                            ? authorizedExpertAdvisorListForRendering.map(
                                (i: any, index: any) => (
                                  <tr key={i.id} style={{ lineHeight: "1.5" }}>
                                    <td style={{ width: "6%" }}>
                                      <div
                                        style={{
                                          height: "55px",
                                          width: "55px",
                                          padding: "1px",
                                          borderRight: "1px solid #cccccc",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "contain",
                                            borderRadius: "3px",
                                          }}
                                          src={
                                            thumbImageURL(i.image_path) || EA
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td style={{ width: "20%" }}>
                                      <p style={{ lineHeight: "1.5" }}>
                                        {" "}
                                        {i.name}
                                        <p style={{ lineHeight: "1.5" }}>
                                          <small> {i.classes}</small>{" "}
                                        </p>
                                      </p>
                                    </td>
                                    <td style={{ width: "45%" }}>{i.info}</td>
                                    <td style={{ width: "15%" }}>
                                      {i?.sellingPrice !== undefined && (
                                        <p style={{ lineHeight: "1.5" }}>
                                          {" "}
                                          USD$ {i.sellingPrice}
                                          <p style={{ lineHeight: "1.5" }}>
                                            {" "}
                                            <small>After discount</small>
                                          </p>{" "}
                                        </p>
                                      )}
                                    </td>
                                    <td style={{ width: "45%", fontSize: 12 }}>
                                      {customFormatDatetimeWithLocale(
                                        i.updatedAt,
                                        locale,
                                      )}
                                    </td>
                                    <td style={{ width: "6%" }}>
                                      <Button
                                        onClick={() =>
                                          navigate(`/expert-advisors/${i.id}`, {
                                            state: { isGranted: i.isGranted },
                                          })
                                        }
                                      >
                                        <FormattedMessage
                                          id="expert.Details"
                                          defaultMessage="Details"
                                        />
                                        <BsChevronRight />
                                      </Button>
                                    </td>
                                    <td>
                                      {i?.ExpertAdvisorVersionList?.[0]
                                        ?.download_path ? (
                                        <>
                                          <ProgressDownload
                                            setLoad={setLoad}
                                            Load={Load}
                                            elId={`p${index}1`}
                                            ref={btnRef}
                                          >
                                            <Button
                                              id={`p${index}`}
                                              onClick={() =>
                                                onDonwloadButtonClick(
                                                  `p${index}`,
                                                  i
                                                    ?.ExpertAdvisorVersionList?.[0]
                                                    ?.download_path,
                                                )
                                              }
                                            >
                                              <DownloadsIcon />
                                            </Button>
                                          </ProgressDownload>
                                        </>
                                      ) : (
                                        <Button
                                          onClick={() =>
                                            toast.warn("No file found")
                                          }
                                        >
                                          <DownloadsIcon />
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                ),
                              )
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              ) : null}
            </section>
            <section className="page-content container-fluid" id="unEA">
              <header className="page-header">
                <div className="d-flex align-items-center">
                  <div className="mr-auto">
                    <h1>
                      <FormattedMessage
                        id="subHead.UnAuthorizedExpertAdvisor"
                        defaultMessage="UnAuthorized Expert Advisors"
                      />
                    </h1>
                  </div>
                </div>
              </header>

              {lstUnauthorizedEA.length > 0 ? (
                <Card style={{ width: "100%" }}>
                  <Card.Body>
                    <div className="table-responsive">
                      <table className="table table-hover table-striped m-0">
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="table.head.image"
                                defaultMessage="Image"
                              />{" "}
                              #
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => setunAuthSorting("name")}
                            >
                              <FormattedMessage
                                id="table.head.Name"
                                defaultMessage="Name"
                              />
                              {unAusortBy === "name" ? (
                                unAusortOrder === "DESC" ? (
                                  <BsSortDown />
                                ) : (
                                  <BsSortUp />
                                )
                              ) : null}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => setunAuthSorting("info")}
                            >
                              <FormattedMessage
                                id="table.head.Description"
                                defaultMessage="Description"
                              />
                              {unAusortBy === "info" ? (
                                unAusortOrder === "DESC" ? (
                                  <BsSortDown />
                                ) : (
                                  <BsSortUp />
                                )
                              ) : null}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => setunAuthSorting("sellingPrice")}
                            >
                              <FormattedMessage
                                id="table.head.Price"
                                defaultMessage="Price"
                              />
                              {unAusortBy === "cost" ? (
                                unAusortOrder === "DESC" ? (
                                  <BsSortDown />
                                ) : (
                                  <BsSortUp />
                                )
                              ) : null}
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => setunAuthSorting("updatedAt")}
                            >
                              <FormattedMessage
                                id="table.head.LastUpdated"
                                defaultMessage="Last Update"
                              />
                              {unAusortBy === "updatedAt" ? (
                                unAusortOrder === "DESC" ? (
                                  <BsSortDown />
                                ) : (
                                  <BsSortUp />
                                )
                              ) : null}
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Details"
                                defaultMessage="Details"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Download"
                                defaultMessage="Download"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {lstUnauthorizedEA.length &&
                            unauthorizedExpertAdvisorListForRendering.map(
                              (i: any) => (
                                <tr key={i.id} style={{ lineHeight: "1.5" }}>
                                  <td style={{ width: "6%" }}>
                                    <div
                                      style={{
                                        height: "55px",
                                        width: "55px",
                                        padding: "1px",
                                        borderRight: "1px solid #cccccc",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                          borderRadius: "3px",
                                        }}
                                        src={thumbImageURL(i.image_path) || EA}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ width: "20%" }}>
                                    <p style={{ lineHeight: "1.5" }}>
                                      {" "}
                                      {i.name}
                                    </p>
                                  </td>
                                  <td style={{ width: "45%" }}>{i.info}</td>
                                  <td style={{ width: "15%" }}>
                                    {i?.sellingPrice !== undefined && (
                                      <p style={{ lineHeight: "1.5" }}>
                                        {" "}
                                        USD$ {i.sellingPrice}
                                        <p style={{ lineHeight: "1.5" }}>
                                          {" "}
                                          <small>After discount</small>
                                        </p>{" "}
                                      </p>
                                    )}
                                  </td>
                                  <td style={{ width: "45%", fontSize: 12 }}>
                                    {customFormatDatetimeWithLocale(
                                      i.updatedAt,
                                      locale,
                                    )}
                                  </td>
                                  <td style={{ width: "6%" }}>
                                    <Button
                                      style={{
                                        backgroundColor: "#68439a",
                                        borderColor: "#68439a",
                                      }}
                                      disabled
                                    >
                                      <FormattedMessage
                                        id="expert.Details"
                                        defaultMessage="Details"
                                      />
                                      <BsChevronRight />
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      style={{
                                        backgroundColor: "#68439a",
                                        borderColor: "#68439a",
                                      }}
                                      disabled
                                    >
                                      <DownloadsIcon />
                                    </Button>
                                  </td>
                                </tr>
                              ),
                            )}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              ) : null}
            </section>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpertAdvisors;
