import { useImperativeHandle, forwardRef } from "react";
import { getFileName } from "../../util/utils";
import * as _ from "lodash";
import axios from "axios";

export const ProgressDownload = forwardRef((props: any, ref: any) => {
  useImperativeHandle(ref, () => ({
    downloadFiles(url: any, elId: any) {
      downloadFile(url, elId);
    },
  }));

  const downloadFile = async (url, id) => {
    const fileName = getFileName(url);
    let str = fileName;
    if (fileName.startsWith("GEMS_v4") && fileName.endsWith(".dll")) {
      str = "GEMS_v4.dll";
    }
    await axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (progressEvent) => {
        const buttonElement = document.getElementById(
          `${id}`,
        ) as HTMLInputElement;
        const element = document.getElementById(`${id}1`) as HTMLInputElement;
        if (
          Math.round((progressEvent.loaded * 100) / progressEvent.total) < 100
        ) {
          if ((document.getElementById(`${id}1`) as HTMLInputElement) != null) {
            (document.getElementById(`${id}1`) as HTMLInputElement).value =
              Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              ).toString();
            element.classList.remove("progress-hide");
          }
          if (buttonElement != null) {
            buttonElement.disabled = true;
          }
          //   $(`#${id}`).off('click')
        } else {
          if (element != null) {
            element.classList.add("progress-hide");
          }
          if (buttonElement != null) {
            buttonElement.disabled = false;
          }
          let arr = props.Load;
          arr = arr.filter(function (item) {
            return item !== id;
          });
          props.setLoad(arr);
        }

        // setPorgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)); // you can use this to show user percentage of file downloaded
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", str);
        document.body.appendChild(link);
        link.click();
        //   setLoad(false);
      })
      .catch(() => {
        //   setLoad(false);
      });
  };

  return (
    <>
      {props?.children}
      <progress
        className="custom-progress progress-hide"
        style={{ width: "100%" }}
        value="0"
        max="100"
        id={props?.elId}
      ></progress>
    </>
  );
});
