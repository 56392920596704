import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import { dispatch } from "../../redux";
import { deleteAllAccountList, getAllAccountList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import AllAccountModal from "../../Components/Account/AllAccountModal";
import { Button, Col, Row } from "react-bootstrap";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { customFormatDatetimeWithLocale } from "../../util/utils";

export const AllAccount = () => {
  const locale = useSelector((state: Store) => state.userDataReducer.local);

  const allAccounts = useSelector(
    (state: Store) => state.accountReducer.allAccount,
  ) as Array<Object>;
  const [modal, setModal] = useState(false);
  const isAPILoading = useSelector(
    (state: Store) => state.accountReducer.isLoading,
  );
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [delModal, setDelModal] = useState({ modal: false, id: "" });
  const [pageNumber, setPageNumber] = useState(1);

  const [deleteLiveAccounts, setDeleteLiveAccounts] = useState([]);
  const [deleteDemoAccounts, setDeleteDemoAccounts] = useState([]);

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  const [filterText, setFilterText] = useState("");
  const [Alltext, setAllText] = useState("");

  const totalRecord = useSelector(
    (state: Store) => state.accountReducer.totalRecord,
  ) as any;

  useEffect(() => {
    dispatch(
      getAllAccountList(
        false,
        pageNumber,
        { by: sortBy, order: sortOrder },
        filterQ,
        pageLimit,
      ),
    );
  }, [filterQ, sortOrder, sortBy, pageNumber, pageLimit]);

  const deleteAccount = () => {
    dispatch(deleteAllAccountList(false, "D", deleteDemoAccounts));
    dispatch(deleteAllAccountList(true, "L", deleteLiveAccounts));

    setDelModal({ modal: false, id: "" });
    setDeleteLiveAccounts([]);
    setDeleteDemoAccounts([]);
    setPageNumber(1);
  };
  const onPageChange = (pageNum) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(filterText);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [filterText]);

  useEffect(() => {
    setFilter(filterText);
  }, [Alltext]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  const onCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    accountType: "L" | "D",
  ) => {
    if (e.target.checked) {
      if (accountType === "L") {
        setDeleteLiveAccounts((oldDeleteLiveAccounts) => [
          ...oldDeleteLiveAccounts,
          e.target.value,
        ]);
      } else {
        setDeleteDemoAccounts((oldDeleteDemoAccounts) => [
          ...oldDeleteDemoAccounts,
          e.target.value,
        ]);
      }
    } else {
      if (accountType === "L") {
        setDeleteLiveAccounts((oldDeleteLiveAccounts) =>
          oldDeleteLiveAccounts.filter((id) => id !== e.target.value),
        );
      } else {
        setDeleteDemoAccounts((oldDeleteDemoAccounts) =>
          oldDeleteDemoAccounts.filter((id) => id !== e.target.value),
        );
      }
    }
  };

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const getAccountTypeText = (type) => {
    if (type === "D") {
      return (
        <FormattedMessage id="table.accountTypeDemo" defaultMessage="demo" />
      );
    }
    return (
      <FormattedMessage id="table.accountTypeLive" defaultMessage="live" />
    );
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <Row>
            {deleteLiveAccounts.length + deleteDemoAccounts.length > 0 && (
              <Button
                size="sm"
                onClick={() => setDelModal({ id: "0", modal: true })}
              >
                <FormattedMessage
                  id="table.head.Delete"
                  defaultMessage="Delete"
                />{" "}
              </Button>
            )}
            <Row className="p-20">
              <Col style={{ display: "inline-flex" }}>
                <label style={{ position: "absolute", bottom: "0" }}>
                  <FormattedMessage id="Filter" defaultMessage="Filter" />
                  {": "}
                </label>
                <input
                  onChange={(e) => {
                    setFilterText(e.target.value);
                  }}
                  type="text"
                  style={{
                    maxWidth: "220px",
                    paddingLeft: "39px",
                    borderRadius: "0px",
                    border: "none",
                    borderBottom: "1px solid",
                  }}
                  className="form-control"
                />
              </Col>
            </Row>
          </Row>
        </Col>
        <Col style={{ textAlign: "end" }}>
          <Button onClick={() => setModal(true)}>
            <FormattedMessage
              id="page.AddAccount"
              defaultMessage=" Add Account"
            />
          </Button>
        </Col>
      </Row>
      {isAPILoading && <CenterLoader />}

      {allAccounts.length > 0 ? (
        <>
          <div className="table-responsive">
            {" "}
            <table className="table table-hover table-striped m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="table.head.Delete"
                      defaultMessage="Delete"
                    />{" "}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("type")}
                  >
                    <FormattedMessage
                      id="menu.type"
                      defaultMessage="Account Type"
                    />{" "}
                    {sortBy === "type" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("account")}
                  >
                    <FormattedMessage
                      id="menu.accounts"
                      defaultMessage="Account"
                    />{" "}
                    #
                    {sortBy === "account" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("ea")}
                  >
                    <FormattedMessage id="table.head.EA" defaultMessage="EA" />
                    {sortBy === "ea" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("version")}
                  >
                    <FormattedMessage
                      id="table.head.Version"
                      defaultMessage="Version"
                    />
                    {sortBy === "version" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("remark")}
                  >
                    <FormattedMessage
                      id="table.head.Remark"
                      defaultMessage="Remark"
                    />
                    {sortBy === "remark" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("latencyInMilliseconds")}
                  >
                    <FormattedMessage
                      id="table.head.latencyInMilliseconds"
                      defaultMessage="Latency (ms)"
                    />
                    {sortBy === "latencyInMilliseconds" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("lastPingAt")}
                  >
                    <FormattedMessage
                      id="table.head.lastPingAt"
                      defaultMessage="Last Ping At"
                    />
                    {sortBy === "lastPingAt" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {allAccounts.length &&
                  allAccounts.map((i: any) => {
                    let checked = false;
                    const str = i?.id?.toString() || "";
                    if (
                      deleteDemoAccounts?.includes(str) ||
                      deleteLiveAccounts?.includes(str)
                    ) {
                      checked = true;
                    }

                    let latencyInMilliseconds = i.latencyInMilliseconds;
                    let lastPingAt = i.lastPingAt;
                    if (lastPingAt === null) {
                      latencyInMilliseconds = "Unknown";
                      lastPingAt = "Unknown";
                    } else {
                      lastPingAt = customFormatDatetimeWithLocale(
                        lastPingAt,
                        locale,
                        true,
                      );
                    }

                    return (
                      <tr key={i.id}>
                        <td>
                          <label className="control control-outline control-primary control--checkbox font-size-14 d-inline-block mb-3 mr-3 pointer">
                            <input
                              type="checkbox"
                              value={i?.id}
                              checked={checked}
                              onChange={(e) =>
                                onCheckboxChange(e, i.accountType)
                              }
                            />{" "}
                            <div className="control__indicator"></div>
                          </label>
                        </td>
                        <td>{getAccountTypeText(i.accountType)}</td>
                        <td>{i.accountNumber}</td>
                        <td>{i?.EA_version?.EA.name || "N/A"}</td>
                        <td>{i?.EA_version?.version || "N/A"}</td>
                        <td>{i.remark}</td>
                        <td>{latencyInMilliseconds}</td>
                        <td>{lastPingAt}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>{" "}
          </div>
          {totalRecord > 10 && (
            <>
              <FormControl className="pagelimit">
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <FormattedMessage id="page_size" defaultMessage="Page Size" />
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={pageLimit}
                  onChange={(e: any) => {
                    setPageLimit(e.target.value);
                    setPageNumber(1);
                  }}
                >
                  <FormControlLabel value={10} control={<Radio />} label="10" />
                  <FormControlLabel value={20} control={<Radio />} label="20" />
                  <FormControlLabel value={50} control={<Radio />} label="50" />
                </RadioGroup>
              </FormControl>
              <div className="mt-3">
                <Pagination
                  itemsPerPage={pageLimit}
                  totalPages={totalRecord}
                  onPageChage={onPageChange}
                  page={pageNumber - 1}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <p>{!isAPILoading && <NoDatFound />}</p>
      )}

      {modal && (
        <AllAccountModal
          headText={"page.AddAccount"}
          onCancle={() => setModal(false)}
          showModal={modal}
        />
      )}

      {delModal.modal && (
        <DeletePopup
          message={
            <FormattedMessage
              id="msg.delete"
              defaultMessage="Are you sure want to delete account ?"
            />
          }
          onClose={() => setDelModal((prv: any) => ({ ...prv, modal: false }))}
          open={delModal.modal}
          onConfirm={() => deleteAccount()}
          isLoading={isAPILoading}
        />
      )}
    </>
  );
};

export default AllAccount;
