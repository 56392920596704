import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import logoImg from "../../assets/img/gems-logo_normal.svg";
import {
  ActivityIcon,
  AnnouncementsIcon,
  ClassesIcon,
  DownloadIcon,
  GiftIcon,
  HomeIcon,
  PointsIcon,
  TicketIcon,
  LibararyIcon,
  AttendnceIcon,
  IndicatorsIcon,
  ScriptIcon,
  UtilititesIcon,
  SignalsIcon,
  AccountIcon,
  VPSMachineIcon,
  FAQIcon,
} from "../../util/iconStyle";
import GemsfxChinese from "../../assets/img/Gemsfx-Version4-Logo - Chinese.png";
import GemsfxEnglish from "../../assets/img/Gemsfx-Version4-Logo - English.png";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { AccessTime } from "@mui/icons-material";

export const Sidebar = () => {
  const location = useLocation();
  const local = useSelector((state: Store) => state.userDataReducer.local);
  const myInfo = useSelector((state: Store) => state.userDataReducer.myInfo);
  const firstClassName =
    (myInfo.userClass || []).filter(
      (classTaken) => classTaken.status === "approved",
    )[0]?.class?.name || "";

  const [openVps, SetOpenVps] = useState(false);
  const [openClass, SetOpenClass] = useState(false);
  const [openSignal, SetOpenSignal] = useState(false);

  const activeClass = (path: string) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };

  const handleInputChange = () => {
    localStorage.removeItem("pageNumber");
    localStorage.removeItem("pageLimit");
  };

  return (
    <>
      <aside className="sidebar sidebar-left">
        <div className="sidebar-content">
          <div className="aside-toolbar">
            <ul className="site-logo">
              <li>
                {/* <!-- START LOGO --> */}
                <Link to="/home">
                  <div className="logo">
                    {local == "en" ? (
                      <img src={GemsfxEnglish} style={{ width: "140px" }} />
                    ) : (
                      <img src={GemsfxChinese} style={{ width: "140px" }} />
                    )}
                  </div>
                </Link>
                {/* <!-- END LOGO --> */}
              </li>
            </ul>
          </div>
          <nav className="main-menu">
            <ul className="nav metismenu">
              <li className="sidebar-header">
                <span>
                  <FormattedMessage
                    id="menu.general"
                    defaultMessage="General"
                  />
                </span>
              </li>
              <li className={activeClass("/home") ? "active" : ""}>
                <Link
                  to="/home"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <HomeIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.dashboard"
                      defaultMessage=" Dashboard"
                    />
                  </span>
                </Link>
              </li>

              <li className={activeClass("/points") ? "active" : ""}>
                <Link
                  to="/points"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <PointsIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.points"
                      defaultMessage="Points"
                    />
                  </span>
                </Link>
              </li>

              <li className={activeClass("/gifts") ? "active" : ""}>
                <Link
                  to="/gifts"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <GiftIcon />
                  </i>
                  <span>
                    <FormattedMessage id="menu.gifts" defaultMessage="Gifts" />
                  </span>
                </Link>
              </li>
              <li className={activeClass("/announcement") ? "active" : ""}>
                <Link
                  to="/announcement"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <AnnouncementsIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.announcement"
                      defaultMessage="Announcements"
                    />
                  </span>
                </Link>
              </li>
              <li className={""}>
                <a
                  href={`https://forexforest.com/hk/enroll/gems-pass?billing_last_name=${myInfo?.englishLastName || ""}&billing_first_name=${myInfo?.englishFirstName || ""}&billing_phone=${myInfo?.phoneNumber}&billing_email=${myInfo?.email}&your_class=${firstClassName}`}
                  target="_blank"
                >
                  <i className="MuiListItemIcon-root">
                    <AccessTime />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.subscription"
                      defaultMessage="Extend Subscription"
                    />
                  </span>
                </a>
              </li>
              <li className={activeClass("/account") ? "active" : ""}>
                <Link
                  to="/account"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <AccountIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.accounts"
                      defaultMessage="Accounts"
                    />
                  </span>
                </Link>
              </li>

              <li className={activeClass("/ticket") ? "active" : ""}>
                <Link
                  to="/ticket"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <TicketIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.ticket"
                      defaultMessage="Tickets"
                    />
                  </span>
                </Link>
              </li>
              <li
                className={
                  activeClass("/vps-creation") || activeClass("/vps-management")
                    ? `${
                        !openVps
                          ? "active nav-dropdown"
                          : "opens active nav-dropdown"
                      }`
                    : `${!openVps ? "nav-dropdown" : "opens nav-dropdown"}`
                }
              >
                <a
                  className="active has-arrow"
                  onClick={() => SetOpenVps(!openVps)}
                  href="#"
                  aria-expanded="false"
                >
                  <span>
                    <i
                      style={{
                        color: activeClass("/vps-creation")
                          ? "#68439a"
                          : activeClass("/vps-management")
                            ? "#68439a"
                            : "#718EB1",
                      }}
                      className="MuiListItemIcon-root"
                    >
                      <VPSMachineIcon />
                    </i>
                    <FormattedMessage
                      id="menu.vpsMachine"
                      defaultMessage="VPS Machines"
                    />
                  </span>
                </a>
                <ul
                  className={`nav-sub ${!openVps ? "collapse" : ""}`}
                  aria-expanded="true"
                >
                  <li>
                    <Link
                      to="/vps-creation"
                      className={
                        activeClass("/vps-creation")
                          ? "active nav-dropdown "
                          : "nav-dropdown"
                      }
                      onClick={() => {
                        handleInputChange();
                      }}
                    >
                      <span
                        style={{
                          color: activeClass("/vps-creation")
                            ? "#68439a"
                            : "#718EB1",
                        }}
                      >
                        <i
                          style={{
                            color: activeClass("/vps-creation")
                              ? "#68439a"
                              : "#718EB1",
                          }}
                          className="MuiListItemIcon-root"
                        >
                          <VPSMachineIcon />
                        </i>
                        <span>
                          <FormattedMessage
                            id="menu.vpsMachine.creation"
                            defaultMessage="VPS Machines Creation"
                          />
                        </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/vps-management"
                      className={
                        activeClass("/vps-management")
                          ? "active nav-dropdown "
                          : "nav-dropdown"
                      }
                      onClick={() => {
                        handleInputChange();
                      }}
                    >
                      <span
                        style={{
                          color: activeClass("/vps-management")
                            ? "#68439a"
                            : "#718EB1",
                        }}
                      >
                        <i
                          style={{
                            color: activeClass("/vps-management")
                              ? "#68439a"
                              : "#718EB1",
                          }}
                          className="MuiListItemIcon-root"
                        >
                          <VPSMachineIcon />
                        </i>
                        <span>
                          <FormattedMessage
                            id="menu.vpsMachine.management"
                            defaultMessage="VPS Machines Management"
                          />
                        </span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="sidebar-header">
                <span>
                  <FormattedMessage id="menu.classes" defaultMessage="Class" />
                </span>
              </li>

              <li
                className={
                  activeClass("/myClass") || activeClass("/allClass")
                    ? `${
                        !openClass
                          ? "active nav-dropdown"
                          : "opens active nav-dropdown"
                      }`
                    : `${!openClass ? "nav-dropdown" : "opens nav-dropdown"}`
                }
              >
                <a
                  className="active has-arrow"
                  onClick={() => SetOpenClass(!openClass)}
                  href="#"
                  aria-expanded="false"
                >
                  <span>
                    <i
                      style={{
                        color: activeClass("/myClass")
                          ? "#68439a"
                          : activeClass("/allClass")
                            ? "#68439a"
                            : "#718EB1",
                      }}
                      className="MuiListItemIcon-root"
                    >
                      <ClassesIcon />
                    </i>
                    <FormattedMessage
                      id="menu.classes"
                      defaultMessage="Classes"
                    />
                  </span>
                </a>
                <ul
                  className={`nav-sub ${!openClass ? "collapse" : ""}`}
                  aria-expanded="true"
                >
                  <li>
                    <Link
                      to="/myClass"
                      className={
                        activeClass("/myClass")
                          ? "active nav-dropdown "
                          : "nav-dropdown"
                      }
                      onClick={() => {
                        handleInputChange();
                      }}
                    >
                      <span
                        style={{
                          color: activeClass("/myClass")
                            ? "#68439a"
                            : "#718EB1",
                        }}
                      >
                        <i
                          style={{
                            color: activeClass("/myClass")
                              ? "#68439a"
                              : "#718EB1",
                          }}
                          className="MuiListItemIcon-root"
                        >
                          <ClassesIcon />
                        </i>
                        <span>
                          <FormattedMessage
                            id="menu.classes.myClass"
                            defaultMessage="My Classes"
                          />
                        </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/allClass"
                      className={
                        activeClass("/allClass")
                          ? "active nav-dropdown "
                          : "nav-dropdown"
                      }
                      onClick={() => {
                        handleInputChange();
                      }}
                    >
                      <span
                        style={{
                          color: activeClass("/allClass")
                            ? "#68439a"
                            : "#718EB1",
                        }}
                      >
                        <i
                          style={{
                            color: activeClass("/allClass")
                              ? "#68439a"
                              : "#718EB1",
                          }}
                          className="MuiListItemIcon-root"
                        >
                          <ClassesIcon />
                        </i>
                        <span>
                          <FormattedMessage
                            id="menu.classes.allClass"
                            defaultMessage="All Classes"
                          />
                        </span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={activeClass("/activities") ? "active" : ""}>
                <Link
                  to="/activities"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <ActivityIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.activities"
                      defaultMessage="Activities"
                    />
                  </span>
                </Link>
              </li>
              <li className={activeClass("/attendance") ? "active" : ""}>
                <Link
                  to="/attendance"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <AttendnceIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.attendence"
                      defaultMessage="Attendence"
                    />
                  </span>
                </Link>
              </li>

              <li
                className={
                  activeClass("/mySignal") || activeClass("/classSignal")
                    ? `${
                        !openSignal
                          ? "active nav-dropdown"
                          : "opens active nav-dropdown"
                      }`
                    : `${!openSignal ? "nav-dropdown" : "opens nav-dropdown"}`
                }
              >
                <a
                  className="has-arrow"
                  onClick={() => SetOpenSignal(!openSignal)}
                  href="#"
                  aria-expanded="false"
                >
                  <span>
                    <i
                      className="MuiListItemIcon-root"
                      style={{
                        color: activeClass("/mySignal")
                          ? "#68439a"
                          : activeClass("/classSignal")
                            ? "#68439a"
                            : "#718EB1",
                      }}
                    >
                      <SignalsIcon />
                    </i>
                    <FormattedMessage
                      id="menu.signal"
                      defaultMessage="Signal"
                    />
                  </span>
                </a>
                <ul
                  className={`nav-sub ${!openSignal ? "collapse" : ""}`}
                  aria-expanded="true"
                >
                  <li>
                    <Link
                      to="/mySignal"
                      className={
                        activeClass("/mySignal")
                          ? "active nav-dropdown "
                          : "nav-dropdown"
                      }
                      onClick={() => {
                        handleInputChange();
                      }}
                    >
                      <span
                        style={{
                          color: activeClass("/mySignal")
                            ? "#68439a"
                            : "#718EB1",
                        }}
                      >
                        <i
                          className="MuiListItemIcon-root"
                          style={{
                            color: activeClass("/mySignal")
                              ? "#68439a"
                              : "#718EB1",
                          }}
                        >
                          <SignalsIcon />
                        </i>
                        <span>
                          <FormattedMessage
                            id="menu.signal.my"
                            defaultMessage="My Signals"
                          />
                        </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/classSignal"
                      className={
                        activeClass("/classSignal")
                          ? "active nav-dropdown "
                          : "nav-dropdown"
                      }
                      onClick={() => {
                        handleInputChange();
                      }}
                    >
                      <span
                        style={{
                          color: activeClass("/classSignal")
                            ? "#68439a"
                            : "#718EB1",
                        }}
                      >
                        <i
                          className="MuiListItemIcon-root"
                          style={{
                            color: activeClass("/classSignal")
                              ? "#68439a"
                              : "#718EB1",
                          }}
                        >
                          <SignalsIcon />
                        </i>
                        <span>
                          <FormattedMessage
                            id="menu.signal.class"
                            defaultMessage="Class Signals"
                          />
                        </span>
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="sidebar-header">
                <span>
                  <FormattedMessage id="menu.Tools" defaultMessage="Tools" />
                </span>
              </li>

              <li className={activeClass("/expert-advisors") ? "active" : ""}>
                <Link
                  to="/expert-advisors"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <DownloadIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.ea"
                      defaultMessage="Expert Advisors"
                    />
                  </span>
                </Link>
              </li>
              <li className={activeClass("/indicators") ? "active" : ""}>
                <Link
                  to="/indicators"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <IndicatorsIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.indicators"
                      defaultMessage="Indicators"
                    />
                  </span>
                </Link>
              </li>
              <li className={activeClass("/utilities") ? "active" : ""}>
                <Link
                  to="/utilities"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <UtilititesIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.utilities"
                      defaultMessage="Utilities"
                    />
                  </span>
                </Link>
              </li>
              <li className={activeClass("/scripts") ? "active" : ""}>
                <Link
                  to="/scripts"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <ScriptIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.scripts"
                      defaultMessage="Scripts"
                    />
                  </span>
                </Link>
              </li>
              <li className={activeClass("/libraries") ? "active" : ""}>
                <Link
                  to="/libraries"
                  onClick={() => {
                    handleInputChange();
                  }}
                >
                  <i className="MuiListItemIcon-root">
                    <LibararyIcon />
                  </i>
                  <span>
                    <FormattedMessage
                      id="menu.libraries"
                      defaultMessage="Libraries"
                    />
                  </span>
                </Link>
              </li>
              <li className={activeClass("/faqs") ? "active" : ""}>
                <Link to="/faqs">
                  <i className="MuiListItemIcon-root">
                    <FAQIcon />
                  </i>
                  <span>
                    <FormattedMessage id="faqs" defaultMessage="FAQs" />
                  </span>
                </Link>
              </li>

              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 3rem 3rem 3rem",
                }}
              >
                <div
                  style={{
                    objectFit: "cover",
                    width: "100%",
                  }}
                >
                  <img
                    src={logoImg}
                    alt=""
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};
