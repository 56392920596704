import { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Store } from "../../redux/Actions";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import LiveAccount from "./LiveAccount";
import DemoAccount from "./DemoAccount";
import AllAccount from "./AllAccount";
import { useSelector } from "react-redux";
import AddAlterNativeName from "../../Components/Account/AddAlterNativeName";
import { FormattedMessage } from "react-intl";
import { getBrokerList, getQR } from "../../redux/action";
import { dispatch } from "../../redux";

const Account = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [Modal, setModal] = useState(false);
  const classes = useStyles();
  const userInfo = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;
  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(getQR());
    dispatch(getBrokerList());
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content">
        <section className="page-content container-fluid">
          <Row className="g-4 mb-5">
            <Col sm={12} md={6} lg={4} className="mb-3 mb-lg-0">
              <Card className="equal-height">
                <Card.Body>
                  <h2>
                    {userInfo.demoAccountCreated}/{userInfo.demoLimit}
                  </h2>
                  <small>
                    <FormattedMessage
                      id="accounts.Smalltag"
                      defaultMessage="(No. of Temporary Accounts: 0)"
                    />
                  </small>
                  <h4>
                    {" "}
                    <FormattedMessage
                      id="accounts.Demo"
                      defaultMessage="Demo Account"
                    />
                  </h4>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 mb-lg-0">
              <Card className="equal-height">
                <Card.Body>
                  <h2>
                    {userInfo.liveAccountCreated}/{userInfo.liveLimit}
                  </h2>
                  <small>
                    <FormattedMessage
                      id="accounts.Smalltag"
                      defaultMessage="(No. of Temporary Accounts: 0)"
                    />
                  </small>
                  <h4>
                    {" "}
                    <FormattedMessage
                      id="accounts.Live"
                      defaultMessage="Live Account"
                    />
                  </h4>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={4} className="mb-3 mb-lg-0">
              <Card className="equal-height">
                <Card.Body>
                  <h2>
                    {" "}
                    <FormattedMessage
                      id="accounts.AlterNmae"
                      defaultMessage="Alternative Names"
                    />
                  </h2>
                  <Button variant="outlined" onClick={() => setModal(true)}>
                    {" "}
                    <FormattedMessage
                      id="accounts.Manage"
                      defaultMessage="Manage"
                    />
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", padding: "0px" }}
                style={{ padding: "0px" }}
                className={classes.tabs}
              >
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.AllAccount"
                        defaultMessage="All Account"
                      />
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.LiveAccount"
                        defaultMessage="Live Account"
                      />
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.DemoAccount"
                        defaultMessage="Demo Account"
                      />
                    }
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={activeTab} index={0}>
                <AllAccount />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <LiveAccount />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <DemoAccount />
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>

      {Modal && (
        <AddAlterNativeName
          onCancle={() => setModal(false)}
          showModal={Modal}
        />
      )}
    </div>
  );
};

export default Account;

const useStyles = makeStyles({
  tabs: {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f57c51",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f57c51",
      BsBorderBottom: "1px solid",
    },
    "& .MuiTypography-root": {
      lineHeight: "1.5",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
          <section className="account-warningMessage">{warningMessage}</section>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const warningMessage =
  'For users of the following brokers: "Axitrader (AXI)", "Go Markets", "JustForex", "VantageFX" if registering your account as NON EXPIRY DEMO does not work, please try registering as LIVE instead.';
